<template>
    <div class="pack">
      <div class="df-row">
        <div class="df-col">
          <el-select
            v-model="submitData.nature_no"
            placeholder="请选择卡属性"
            size="mini"
            clearable
            @change="onChangeCard"
          >
            <el-option
              v-for="item in natureSelector"
              :key="item.NatureNo"
              :label="item.NatureName"
              :value="item.NatureNo"
            >
            </el-option>
          </el-select>
        </div>
  
        <div class="df-col">
          <el-select
            v-model="submitData.card_guid"
            placeholder="请选择卡类型"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in [
                {
                  CardGuid: '',
                  CardName: '全部',
                  NatureNo: '',
                },
                ...cardSelector,
              ]"
              :key="item.CardGuid"
              :label="item.CardName"
              :value="item.CardGuid"
            >
            </el-option>
          </el-select>
        </div>
  
        <div class="df-col">
          <el-select
            v-model="submitData.vip_state"
            placeholder="请选择状态"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in stateSelector"
              :key="item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </div>
  
        <div class="df-col">
          <el-input
            v-model="submitData.vip_word"
            placeholder="会员姓名/卡号/手机号"
            size="mini"
            clearable
          ></el-input>
        </div>
  
        <div class="df-col">
          <el-input
            v-model="submitData.item_word"
            placeholder="项目名称/项目编号"
            size="mini"
            clearable
          ></el-input>
        </div>
  
        <div class="df-col">
          <el-date-picker
            v-model="multipleDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            value-format="yyyy-MM-dd"
            @change="onChangeDate"
          >
          </el-date-picker>
        </div>

        <div class="df-col">
          <el-checkbox v-model="submitData.use_software_date" :true-label="1" :false-label="0">按记账日期</el-checkbox>
        </div>
  
        <div class="df-col">
          <el-button type="primary" size="mini" @click="getVipPackItemList"
            >查询</el-button
          >
        </div>
  
        <div class="df-col">
          <!-- <DfExportButton
            ref="dfExportButton"
            :fileName="fileName"
            @export="onExportExcel"
          ></DfExportButton> -->
        </div>
      </div>
  
      <div class="df-row">
        <div class="df-col summary_number">
          开卡次数:<span class="value">{{
            totalInfo.TotalPackCount
          }}</span
          >次；剩余次数:<span class="value">{{ totalInfo.TotalSurpCount }}</span
          >次；剩余消耗额:<span class="value">{{ totalInfo.TotalSurpConsume }}</span
          >元
        </div>
      </div>
  
      <div class="print">
        <el-table
          id="exprot-table"
          :data="dataList"
          v-loading="loading"
          :max-height="tableMaxHeight"
          stripe
          border
          fit
          size="mini"
        >
          <el-table-column label="会员姓名" prop="MemberName" width="120"> </el-table-column>
          <el-table-column label="会员电话" prop="MemberPhone" width="100">
          </el-table-column>
          <el-table-column label="会员卡号" prop="VipNo" width="100">
          </el-table-column>
          <el-table-column label="卡类型" prop="CardName" width="120">
          </el-table-column>
          <!-- <el-table-column label="项目类型" prop="">
            <template slot-scope="scope">赠送</template>
          </el-table-column> -->
          <el-table-column label="批次" prop="PackBatch"> </el-table-column>
          <el-table-column label="编号" prop="ItemNo"></el-table-column>
          <el-table-column label="名称" prop="ItemName" width="140">
          </el-table-column>
          <el-table-column label="单位" prop="UnitsName"> </el-table-column>
          <el-table-column label="开卡次数" prop="PackCount"> </el-table-column>
          <el-table-column label="剩余次数" prop="SurpCount"> </el-table-column>
          <el-table-column label="消耗额" prop="Consume"> </el-table-column>
          <el-table-column label="不限次数">
            <template slot-scope="scope">{{
              scope.row.InfiniteMark ? "是" : "否"
            }}</template>
          </el-table-column>
          <el-table-column label="组别" prop="UniteName"> </el-table-column>
          <el-table-column label="赠送日期" prop="" width="100">
            <template slot-scope="scope">
              {{
                scope.row.PackDate
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row.PackDate * 1000)
                    )
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="有效日期" prop="" width="100">
            <template slot-scope="scope">
              {{
                scope.row.ValidDate
                  ? util.dateFormat(
                      "YYYY-mm-dd",
                      new Date(scope.row.ValidDate * 1000)
                    )
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="PackNote" width="140">
          </el-table-column>
          <el-table-column label="所属门店" prop="StoresName" width="200">
          </el-table-column>
        </el-table>
      </div>
  
      <!-- start 分页器 -->
      <el-pagination
        background
        :page-sizes="[5, 10, 15, 20]"
        :page-size="submitData.page_size"
        :current-page="submitData.page_index"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalInfo.TotalRecord"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
      <!--end 分页器-->
    </div>
  </template>
  
  <script>
  import Vip from "@/api/vip.js";
  
  export default {
    data() {
      return {
        tableMaxHeight: "",
        fileName: "",
        submitData: {
          begin_date: "",
          end_date: "",
          nature_no: "",
          card_guid: "",
          vip_state: 0,
          vip_word: "",
          item_word: "",
          page_size: 20,
          page_index: 1,
          use_software_date: 0
        },
        totalInfo: {
          TotalPackCount: 0,
          TotalPage: 0,
          TotalRecord: 0,
          TotalSurpConsume: 0,
          TotalSurpCount: 0,
        },
        multipleDate: [],
        copyCardSelector: [],
        cardSelector: [],
        natureSelector: [],
        stateSelector: [],
        dataList: [],
        loading: false,
      };
    },
  
    created() {
      this.getResize();
    },
  
    mounted() {
      window.addEventListener("resize", this.getResize);
      this.multipleDate = [
        this.util.getNowMonthToday(),
        this.util.getNowMonthToday(),
      ];
      this.submitData.begin_date = this.util.getNowMonthToday();
      this.submitData.end_date = this.util.getNowMonthToday();
  
      this.initVipItemQuery();
    },
  
    destroyed() {
      window.removeEventListener("resize", this.getResize);
    },
  
    methods: {
      // 获取浏览器窗口大小
      getResize() {
        let { clientHeight } = this.util.getDocumentElementInfo();
        this.tableMaxHeight = clientHeight - (100 + 68 + 64);
      },
  
      // 初始化查询套卡和赠送项目
      async initVipItemQuery() {
        try {
          let { data } = await Vip.initVipItemQuery();
  
          this.copyCardSelector = data.cardSelector;
          this.natureSelector = [
            { NatureName: "全部", NatureNo: "" },
            ...data.natureSelector,
          ];
          this.stateSelector = [
            { Name: "全部", Value: 0 },
            ...data.stateSelector,
          ];
        } catch (err) {
          console.log(err);
        }
      },
  
      // 获取查询套卡和赠送项目
      async getVipPackItemList() {
        try {
          let { data } = await Vip.getVipPackItemList(this.submitData);
  
          this.dataList = data.list;
          this.totalInfo = data.filter;
        } catch (err) {
          console.log(err);
        }
      },
  
      onChangeCard(event) {
        let { copyCardSelector } = this;
        let arr = copyCardSelector.filter((item) => item.NatureNo == event);
        // let param = {
        //   CardGuid: "",
        //   CardName: "全部",
        //   NatureNo: "",
        // };
        this.cardSelector = arr.length > 0 ? arr : [];
        console.log(this.cardSelector);
      },
  
      // 日期时间改变事件
      onChangeDate() {
        this.submitData.begin_date =
          this.multipleDate.length > 0
            ? this.multipleDate[0]
            : this.util.getNowMonthToday();
        this.submitData.end_date =
          this.multipleDate.length > 0
            ? this.multipleDate[1]
            : this.util.getNowMonthToday();
        this.submitData.page_index = 1;
      },
  
      // 分页：每页显示多少条数据
      handleSizeChange(event) {
        this.submitData.page_index = 1;
        this.submitData.page_size = event;
        this.getVipPackItemList();
      },
  
      // 分页：跳转到第几页
      handleCurrentChange(event) {
        this.submitData.page_index = event;
        this.getVipPackItemList();
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .pack {
    .df-row {
      .flex-row;
      .df-col {
        .el-select {
          width: 140px;
        }
  
        .el-input {
          min-width: 100px;
        }
  
        .el-date-editor {
          width: 240px !important;
        }
      }
    }
    .print {
      // margin-top: 10px;
    }
    .value {
      color: #f00;
    }
  
    .el-pagination {
      margin-top: 20px;
      float: right;
    }
  }
  </style>
  